import httpUtil from "@/utils/httpUtil";

/**设置公司商家互通 */
export const setCompanyEachLink = async params => httpUtil.post("/api/crmPc/companyGoods/setCompanyEachLink", params)

/**获得公司商家互通列表 */
export const getCompanyEachLinkList = async params => httpUtil.post("/api/crmPc/companyGoods/getCompanyEachLinkList", params)

/**删除商家互通 */
export const delCompanyEachLink = async params => httpUtil.post("/api/crmPc/companyGoods/delCompanyEachLink", params)

/**查询公司信息 */
export const getCompanyAllInfo = async params => httpUtil.post("/api/crmPc/companyGoods/getCompanyAllInfo", params)

/**获得公司商品互通列表 */
export const getCompanyGoodsLinkList = async params => httpUtil.post("/api/crmPc/companyGoods/getCompanyGoodsLinkList", params)

/**设置公司商品互通 */
export const setCompanyGoodsLink = async params => httpUtil.post("/api/crmPc/companyGoods/setCompanyGoodsLink", params)

/**查询简单商品分类 */
export const getGoodsTypeInfo = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsTypeInfo", params)

/**查询商品信息 */
export const getGoodsInfoPC = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPC", params)

/**获得公司商品共享库列表 */
export const getCompanyGoodsBankList = async params => httpUtil.post("/api/crmPc/companyGoods/getCompanyGoodsBankList", params)

/**设置公司商品共享库 */
export const setCompanyGoodsBank = async params => httpUtil.post("/api/crmPc/companyGoods/setCompanyGoodsBank", params)

/**字典 */
export const getByDictType = async params => httpUtil.post("/api/crmPc/sysDict/getByDictType", params);